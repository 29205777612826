<template>
  <div class="org-chart">
    <p class="org-chart-title">
      <span class="h3">组织架构</span>
      <span class="info">(点击组织架构查看更多信息)</span>
    </p>
    <div>
      <org-chart
        class="department-org-chart"
        :data="group"
        :horizontal="horizontal"
        :collapsable="collapsable"
        :label-class-name="labelClass"
        :render-content="renderContent"
        @on-expand="onExpand"
        @on-node-click="onNodeClick">
        >
      </org-chart>
    </div>
    <slider :visible.sync="memberVisible"
            :width="'45%'">
      <div class="employee-list">
        <el-radio-group v-model="position" size="small" class="employee-filter">
          <el-radio-button label="current" @click.native="selectCommand('current')">当前部门成员</el-radio-button>
          <el-radio-button label="other" @click.native="selectCommand('other')">其他部门成员</el-radio-button>
          <el-radio-button label="children" @click.native="selectCommand('children')">创建下级部门</el-radio-button>
        </el-radio-group>

        <employee v-show="position !== 'children'" ref="employee"></employee>

       <div class="child-create" v-show="position === 'children'">
         <p class="danger">请注意，部门一旦创建则不允许删除</p>
         <el-form label-width="80px" size="small" >
           <el-form-item label="部门名称">
             <el-input placeholder="请输入部门名称" v-model="departmentName"/>
           </el-form-item>
           <el-form-item>
             <el-button type="primary" @click="handleCreateDepartment">确定</el-button>
           </el-form-item>
         </el-form>
       </div>

      </div>
    </slider>
  </div>

</template>

<script>
  import OrgChart from '@/components/OrgChart'
  import Slider from '@/components/Slider'
  import Employee from './employee'
  import {departmentList, departmentCreate} from '@/api/account/department'

  export default {
    name: '',
    components: {
      OrgChart,
      Slider,
      Employee
    },
    mounted: function () {
      this.loadGroups()
    },
    data: function () {
      return {
        horizontal: true,
        collapsable: true,
        memberVisible: false,
        position: 'current',
        currentUuid: '',
        departmentName: '',
        group: {}
      }
    },
    methods: {
      renderContent(h, data) {
        return data.label;
      },
      onExpand(data) {
        if ("expand" in data) {
          data.expand = !data.expand;
          if (!data.expand && data.children) {
            this.collapse(data.children);
          }
        } else {
          this.$set(data, "expand", true);
        }
      },
      onNodeClick(e, data) {
        this.currentUuid = data.uuid
        this.$refs.employee.changeDepartment(this.currentUuid, true)
        this.position = 'current'
        this.memberVisible = true
      },

      selectCommand(val) {
        if (this.position === val) {
          return;
        }
        this.position = val
        switch (val) {
          case 'current':
            this.$refs.employee.changeDepartment(this.currentUuid, true)
            break;
          case 'other':
            this.$refs.employee.changeDepartment(this.currentUuid, false)
            break
          default:
            this.departmentName = ''
        }
      },
      loadGroups() {
        departmentList().then(res => {
          this.group = res.data
        })
      },
      labelClass(node) {
        switch (node.level) {
          case 0:
            return 'first';
          case 1:
            return 'second';
          case 2:
            return 'three';
          default:
            return 'other';
        }
      },
      handleCreateDepartment() {
        if (this.departmentName) {
          departmentCreate({
            parentUuid: this.currentUuid,
            name: this.departmentName,
          }).then( res => {
            this.$message.success("创建成功")
            this.loadGroups()
          })
        } else {
          this.$message.warning("请输入部门名称")
        }
      }
    },
  }
</script>

<style lang="scss">
  .org-chart {
    padding: $padding;

    .org-chart-title {
      height: $_header_sec_height;
      line-height: 1;

      .info {
        font-size: .6rem;
      }
    }

    .first, .second, .three, .other {
      font-size: .6rem;
      cursor: pointer;
      border-radius: .2rem;
    }

    .first:hover, .second:hover, .three:hover, .other:hover {
      background-color: $_theme_color;
      border: .1rem solid $_theme_color;
      color: $background-color-light;
    }

    .first {
      border: .1rem solid $_theme_color;
    }

    .second {
      border: .1rem solid $btn_color;
    }

    .three {
      border: .1rem solid $btn_sec_color;
    }

    .danger{
      color: $btn_sec_color;
      margin-bottom: $margin;
    }

    .other {
      border: .1rem solid #f0f0f0;
    }

    .employee-list {
      text-align: center;
      padding: $padding;

      .employee-filter {
        margin: $margin 0;
      }
    }
  }

  .child-create{
    width: 50%;
    margin: $margin auto;
  }

  body.dark .employee-filter .el-radio-button__inner {
    color: #AAAFBA !important;
  }

  body.dark .employee-filter {
    .is-active .el-radio-button__inner {
      color: white !important;
    }
  }
</style>
