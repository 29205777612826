<template>
  <div class="department-employee">
    <div class="employee-data-list">
      <el-table :data="employees" align="center">
        <el-table-column label="成员信息" align="center">
          <template slot-scope="scope">
            <div class="employee-info">
              <div class="employee-info-img" v-if="scope.row.avatar"><img :src="scope.row.avatar"/></div>
              <div class="employee-info-img" v-if="!scope.row.avatar"><img :src="src"/></div>
              <div class="employee-info-detail">
                <p>{{scope.row.name}}</p>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="手机" align="center" prop="phone"></el-table-column>
        <el-table-column label="部门" align="center" prop="departmentName"></el-table-column>
        <el-table-column label="操作" align="center" prop="name">
          <template slot-scope="scope">
            <p v-show="scope.row.uuid != employee.enterpriseUuid && (!pagination.current)">
              <span @click="handleBind(scope.row)" class="employee-edit">绑定部门</span>
            </p>
            <p v-show="scope.row.uuid == employee.enterpriseUuid" disable>
              不可操作
            </p>

          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination
      @current-change="handleCurrentChange"
      class="pagination"
      :current-page="pagination.curPage"
      :page-size="pagination.limit"
      layout="total, prev, pager, next, jumper"
      :total="pagination.total">
    </el-pagination>
  </div>
</template>

<script>
  import {departmentEmployeeUnion, departmentEmployeeList, departmentBindEmployee} from '@/api/account/department'
  import {mapGetters} from "vuex";

  export default {
    name: '',
    components: {},
    computed: {
      ...mapGetters(['employee'])
    },
    mounted: function () {
    },
    data: function () {
      return {
        employees: [],
        src: require('@/assets/img/avatar.jpg'),
        pagination: {
          departmentUuid: '',
          current: true,
          start: 0,
          curPage: 1,
          limit: 10,
          total: 0,
        },
      }
    },
    methods: {
      initEmployee() {
        this.pagination.start = 0
        this.pagination.phone = ''
        departmentEmployeeUnion(this.pagination).then(res => {
          this.employees = res.data.list || []
          this.pagination.total = parseInt(res.data.total)
        })
      },
      changeDepartment(departmentUuid, isCurrent = true) {
        this.pagination.departmentUuid = departmentUuid
        this.pagination.current = isCurrent
        this.initEmployee()
      },
      handleCurrentChange(value) {
        this.pagination.curPage = value
        this.pagination.start = (value - 1) * this.pagination.limit
        departmentEmployeeList(this.pagination).then(res => {
          this.employees = res.data || []
        })
      },

      handleBind(employee) {
        departmentBindEmployee({
          uuid: employee.uuid,
          departmentUuid: this.pagination.departmentUuid
        }).then(res => {
          this.handleCurrentChange(this.pagination.curPage)
        })
      },
    },
  }
</script>

<style lang="scss">
  .employee-data-list {
    flex: 1;

    .employee-info {
      display: flex;
      align-items: center;
      text-align: center;

      .employee-info-img {
        img {
          max-width: 2rem;
        }
      }

      .employee-info-detail {
        margin-left: $padding;

        p:first-child {
          font-size: .8rem;
        }
      }
    }



    .el-table--group::after, .el-table--border::after, .el-table::before {
      background-color: transparent;
    }

    .el-table__row:hover {
      // background-color: $bg_sec_color;
    }

    .employee-edit, .employee-delete {
      cursor: pointer;
      display: inline-block;
      margin-right: $padding;
    }

    .employee-edit {
      color: $btn_color;
    }

    .employee-delete {
      color: $btn_sec_color;
    }

  }

  .pagination {
    margin: $padding;
    text-align: right;
  }


</style>
