<template>
    <transition name="slide-fade">
        <div class="slide-info" v-show="visible" @click.capture.self="close">
            <div class="slide-content" @click.stop.prevent :style="{width: width}">
                <div class="slide-body">
                    <slot></slot>
                </div>

            </div>

        </div>
    </transition>

</template>

<script>
    export default {
        name: '',
        components: {},
        props: {
            visible: {
                type: Boolean,
                default: false
            },
            width: {
                type: String,
                default: '65%'
            },
        },
        mounted: function () {
        },
        data: function () {
            return {}
        },
        methods: {
            close() {
                this.$emit('update:visible', false);
            },
        },
    }
</script>

<style lang="scss">
    .slide-info {
        position: fixed;
        top: $_header_height;
        height: calc(100% - 61px);
        width: 100%;
        left: 0;
       background-color: rgba(0,0,0,0.25);
        z-index: 99;
        .slide-content {
            position: absolute;
            margin-top: 1px;
            right: 0;
            height: 100%;
           @include set_bg_color($background-color-light, #42464e);
            .slide-body {
                max-height: 100%;
                height: 100%;
                overflow-y: scroll;
            }
        }
    }

    .slide-fade-enter-active {
        transition: all .3s ease;
    }

    .slide-fade-leave-active {
        transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
    }

    .slide-fade-enter, .slide-fade-leave-to
        /* .slide-fade-leave-active for below version 2.1.8 */
    {
        transform: translateX(10px);
        opacity: 0;
    }
</style>
